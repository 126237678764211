import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/Layout'
import PageBanner from '../components/PageBanner'
import Content, { HTMLContent } from '../components/Content'

const MaxWidthContainer = styled.div`
  display: grid;
  position: relative;
  background-color: #f5f5f5;
  margin: 0 auto;
  padding: 48px 0;
  grid-template-columns: minmax(24px, auto) minmax(auto, 1300px) minmax(
      24px,
      auto
    );
  grid-template-areas:
    '. content .'
    '. book .';
`

// eslint-disable-next-line
export const GeneralPageTemplate = ({
  location,
  title,
  content,
  contentComponent,
}) => {
  const PageContent = contentComponent || Content
  return (
    <>
      <PageBanner location={location} title={title} />
      <MaxWidthContainer>
        <PageContent style={{ gridArea: 'content' }} content={content} />
        {/* <LearnMore style={{ gridArea: "book" }} to="/#contact">Book a call today &#8250;</LearnMore> */}
      </MaxWidthContainer>
    </>
  )
}

GeneralPageTemplate.propTypes = {
  location: PropTypes.object,
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  banner: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

const GeneralPage = ({ location, data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout location={location}>
      <GeneralPageTemplate
        location={location}
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        banner={post.frontmatter.banner}
      />
    </Layout>
  )
}

GeneralPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object,
}

export default GeneralPage

export const GenralPageQuery = graphql`
  query GeneralPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
