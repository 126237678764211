import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import banner from '../img/banner.jpg'

const Container = styled.div`
  position: relative;
  display: grid;
  grid-area: ${({ gridArea }) => gridArea};
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(0.25turn, #061814, #74653f);
    grid-area: ${({ gridArea }) => gridArea};
    opacity: 0.75;
  }
`

export default function FullWidthImage(props) {
  const {
    height = '100%',
    imgPosition = 'top left',
    withCurve = false,
    gridArea,
  } = props

  return (
    <Container gridArea={gridArea}>
      <img
        src={banner}
        objectfit={'cover'}
        objectposition={imgPosition}
        style={{
          gridArea: '1/1',
          // You can set a maximum height for the image, if you wish.
          height: height,
          position: 'absolute',
          width: '100%',
          objectFit: 'cover',
        }}
        // This is a presentational image, so the alt should be an empty string
        alt=""
      />

      {withCurve && (
        <svg
          style={{ position: 'absolute', bottom: -1, zIndex: 1 }}
          viewBox="0 0 1507 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M450 4.5001C1055.5 -16 1506.29 47.5058 1506.29 47.5058L-5.7146 47.5058C-14.2146 47.5058 155.998 14.454 450 4.5001Z"
            fill="white"
            stroke="white"
          />
        </svg>
      )}
    </Container>
  )
}

FullWidthImage.propTypes = {
  img: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  height: PropTypes.number,
  subheading: PropTypes.string,
}
